<template lang="html">
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0084 19.4435C14.0084 19.9368 13.7234 20.3849 13.2774 20.594C13.105 20.6756 12.9202 20.715 12.7366 20.715C12.4459 20.715 12.1579 20.6151 11.9252 20.4222L4.97384 14.662H1.27152C0.569402 14.6626 0 14.0929 0 13.3907V8.32481C0 7.62239 0.569402 7.05299 1.27152 7.05299H4.97414L11.9255 1.29276C12.3053 0.977966 12.832 0.910854 13.2777 1.12152C13.7234 1.33068 14.0087 1.7791 14.0087 2.27206L14.0084 19.4435ZM18.895 17.4566C18.864 17.4587 18.8342 17.4599 18.8035 17.4599C18.4676 17.4599 18.1438 17.3272 17.9046 17.0876L17.7345 16.917C17.2885 16.4719 17.2361 15.7668 17.6117 15.2606C18.5639 13.9767 19.0665 12.4548 19.0665 10.8582C19.0665 9.141 18.4959 7.5303 17.4161 6.20009C17.005 5.69449 17.0429 4.96017 17.5037 4.49971L17.6734 4.32967C17.9274 4.07567 18.2678 3.93723 18.6362 3.9592C18.9949 3.97726 19.3299 4.14609 19.5574 4.42417C21.0552 6.25697 21.8464 8.48221 21.8464 10.8585C21.8464 13.0717 21.1467 15.1784 19.8225 16.9498C19.6013 17.245 19.2631 17.4304 18.895 17.4566Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CallAgainTwo",
};
</script>
